body {
	background-color: #fff;
	font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", sans-serif;
	margin: 0;
}

a {
	color: inherit; /* blue colors for links too */
	text-decoration: inherit; /* no underline */
}
