.backlog-overlay {
  overflow: auto;
  margin: auto;
  z-index: 5;
}

.backlog {
  box-sizing: border-box;
  background-color: #eee;
  background-image: linear-gradient(90deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px),
    linear-gradient(#ddd 2px, transparent 2px);
  background-size: 100% 33.33%;
  font-size: 22px;
  width: 90%;
  margin: auto;
  height: 104px;
  padding-top: 3px;
  line-height: 1.52;
  padding-left: 93px;
}

.backlog-jump-container {
  position: absolute;
  transform: translate(-80px, 33px);
  color: #2d5986;
  font-style: italic;
  cursor: pointer;
}

.backlog-jump-text {
  transition: all 0.2s ease-in-out;
  position: relative;
}

.backlog-jump-text:after {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 0px;
  height: 2px;
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
  left: 0px;
  background-color: #2d5986;
}
.backlog-jump-container:hover .backlog-jump-text:before,
.backlog-jump-container:hover .backlog-jump-text:after {
  width: 100%;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .backlog {
    background-image: linear-gradient(90deg, transparent 50px, #abced4 50px, #abced4 52px, transparent 52px),
      linear-gradient(#ddd 2px, transparent 2px); /* shifts blue line margin to left */
    padding-left: 59px;
    font-size: 18px;
    padding-top: 2px;
    line-height: 1.45;
    background-size: 100% 25%;
  }
  .backlog-jump-container {
    transform: translate(-55px, 26px);
  }
  .backlog-jump-text:after {
    bottom: -2.5px;
  }
}

.backlog-speaker {
  font-weight: 500;
  color: DarkGreen;
}
