.overlay-save-load {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #4ea6ca;
  z-index: 5;
  align-items: center;
}
@media only screen and (max-width: 1279px) {
  .overlay-save-load {
    overflow: auto;
  }
}

.save-load-slot {
  overflow: hidden;
  min-width: 1280px;
  min-height: 710px;
  transform: translateY(-80px) scale(0.625);
  background: #000;
  cursor: pointer;
  box-shadow: 0px 0 20px 2px #222;
  align-self: flex-start;
  transition: 0.2s;
}

/* @media only screen and (max-width: 880px) {
  .save-load-slot {
    transform: translateY(-80px) scale(0.45);
  }
}

@media only screen and (max-width: 600px) {
  .save-load-slot {
    transform: translateY(-80px) scale(0.275);
  }
} */

.save-load-slot:hover {
  box-shadow: 0px 0 40px 2px #222;
}

.save-load-slot button:hover {
  cursor: pointer;
}

.overlay-choices-slot {
  z-index: 9;
  width: 1280px;
  height: 720px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.save-load-slot > button > img {
  width: 100%;
  height: 100%;
}

.save-load-slot > .overlay-choices-slot {
  display: none;
}

.save-load-slot > button {
  display: flex;
  justify-content: center;
  transform: translate(0);
  background-color: unset;
  border: unset;
  padding: 0;
  height: 100%;
}

.slot-date {
  position: absolute;
  bottom: 110px;
  text-align: center;
  width: 100%;
  font-size: 26px;
  color: white;
}

.save-load-buttons {
  position: absolute;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  bottom: 50px;
  width: 100%;
  height: 40px;
}

.save-load-btn {
  padding: 10px 16px;
  margin: 2px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #0096cc;
  border: none;
  border-radius: 8px;
  /* box-shadow: 0 4px #007099; */
}

.save-load-btn:hover {
  background-color: #007099;
  transform: translateY(-4px);
}



@media screen and (max-width: 833px) {
  .save-load-slot {
    min-width: unset;
    min-height: unset;
    width: 60%;
    position: relative;
    top: 15px;
  }

  .empty {
    height: 60%;
  }

  .slot-date {
    font-size: 16px;
  }

  .save-load-btn {
    font-size: 10px;
    padding: 10px;
    margin: 5px;
  }

  .save-load-buttons{
    height: unset;
    bottom: 10px;
  }
}