#config-overlay {
	display: flex;
	flex-flow: column;
	z-index: 5;
}

@keyframes stripe-slide {
	0% {
		background-position: 0% 0;
	}
	100% {
		background-position: 100% 0;
	}
}

#config-overlay > ul {
	list-style-type: none;
	margin: 0 0 15px 0;
	padding: 0;
	text-align: center;
}

/* Edited from source: https://codepen.io/Zeindelf/pen/vZbyEg */

.config-btn {
	overflow: visible;
	margin: 0 20px;
	padding: 0;
	border: 0;
	background: transparent;
	font: inherit;
	line-height: normal;
	cursor: pointer;
	-moz-user-select: text;
	text-decoration: none;
	text-transform: uppercase;
	padding: 16px 36px 22px;
	background-color: rgba(0, 0, 0, 0.8);
	color: #fff;
	border: 2px solid #444;
	border-radius: 4px;
	transition: all 0.5s ease;
}
.config-btn::-moz-focus-inner {
	padding: 0;
	border: 0;
}
.config-btn--stripe {
	overflow: hidden;
	position: relative;
}
.config-btn--stripe:after {
	content: "";
	display: block;
	height: 7px;
	width: 100%;
	background-image: repeating-linear-gradient(45deg, #666, #666 1px, transparent 2px, transparent 5px);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-top: 1px solid #666;
	position: absolute;
	left: 0;
	bottom: 0;
	background-size: 7px 7px;
}

.config-btn--stripe:hover,
.active {
	background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border-color: #000;
}

.config-btn--stripe:hover:after,
.active:after {
	background-image: repeating-linear-gradient(45deg, #fff, #fff 1px, transparent 2px, transparent 5px);
	border-top: 1px solid #000;
	animation: stripe-slide 12s infinite linear forwards;
}

.config-container {
	margin: 15px 30px;
	border-radius: 4px;
	background: white;
	width: 375px;
}

.slider-container {
	padding: 15px;
	padding-bottom: 10px;
	display: block;
	margin: 30px auto;
}

.font-container {
	padding: 15px;
	line-height: 1.6;
	display: block;
	margin: 30px auto;
}

.rangeslider .rangeslider__handle {
	border: unset;
}

@media screen and (max-width: 833px) {
	.config-container {
		width: 70%;
		margin: 15px auto;
	}
	.config-btn {
		margin: 0 10px;
		padding: 10px 20px 15px;
    	font-size: 12px;
	}

	.slider-container {
		padding: 15px;
		padding-bottom: 10px;
		display: block;
		margin: 30px auto;
		zoom: 0.6;
		width: 30%;
	}
	
	.font-container {
		padding: 15px;
		line-height: 1.6;
		display: block;
		margin: 30px auto;
		zoom: 0.6;
		width: 30%;
	}
}
