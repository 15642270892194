.sprite {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	font-size: 0;
}

.sprite-center-parent {
	width: 1280px;
	height: 720px;
	position: fixed;
	left: 50%;
	margin-left: -640px;
	font-size: 0;
}

.left {
	left: -350px;
	right: 350px;
	font-size: 0;
}
.right {
	left: 350px;
	right: -350px;
	font-size: 0;
}
