.container {
  overflow-x: hidden;
  margin: auto;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.zoom-frame {
  z-index: 1;
}

@media only screen and (max-width: 1279px) {
  .container {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    overflow-x: hidden;
  }
}

.header {
  list-style-type: none;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: black;
  align-self: flex-start;
}

.header li {
  display: inline-block;
}

.header a, .header span, .header button {
  font-size: 36px;
  display: block;
  color: white;
  text-align: center;
  padding: 8px 22px;
  text-decoration: none;
}

.exit-button {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.exit-button button {
  transition: 300ms;
  cursor: pointer;
  background-color: unset;
  border: unset;
}

.exit-button button:hover {
  background: #e61919;
}

.exit-button button:focus {
  outline: unset;
}

.bg {
  position: fixed;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1279px) {
  .bg {
    /* width: unset; */
  }
}

.overlay {
  position: absolute;
  
  height: 100%;
  z-index: 2;
  /* background-color: #4ea6ca; */
}

#spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.viewPortrait {
  display: none;
}

.viewPortraitImg {
  height: 100%;
  margin: 0 auto;
  display: block;
}

@media only screen and (max-width: 500px) {
  .viewPortrait {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #1b1b1b;
    z-index: 4;
  }
}