/* React CSS Transition Groups animations*/

/* scene  */

.scene-change-enter {
  opacity: 0;
}

.scene-change-enter.scene-change-enter-active {
  opacity: 1;
  transition: opacity 2000ms ease-out;
}

.scene-change-leave {
  opacity: 1;
}

.scene-change-leave.scene-change-leave-active {
  opacity: 0;
  transition: opacity 2000ms ease-in;
}

/* gooose  */

.gooose-enter {
  opacity: 0;
}

.gooose-enter.gooose-enter-active {
  opacity: 0.5;
  transition: opacity 200000ms ease-out;
}

.gooose-leave {
  opacity: 1;
}

.gooose-leave.gooose-leave-active {
  opacity: 0;
  transition: opacity 400ms ease-in;
}

/* default  */

.default-transition-enter {
  opacity: 0;
}

.default-transition-enter.default-transition-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-out;
}

.default-transition-leave {
  opacity: 1;
}

.default-transition-leave.default-transition-leave-active {
  opacity: 0;
  transition: opacity 400ms ease-in;
}

/* 400  */

.transition-400-enter {
  opacity: 0;
}

.transition-400-enter.transition-400-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-out;
}

.transition-400-leave {
  opacity: 1;
}

.transition-400-leave.transition-400-leave-active {
  opacity: 0;
  transition: opacity 400ms ease-in;
}

/* 800  */

.transition-800-enter {
  opacity: 0;
}

.transition-800-enter.transition-800-enter-active {
  opacity: 1;
  transition: opacity 800ms ease-out;
}

.transition-800-leave {
  opacity: 1;
}

.transition-800-leave.transition-800-leave-active {
  opacity: 0;
  transition: opacity 800ms ease-in;
}
