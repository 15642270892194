@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,800,700,300);
@import url(https://fonts.googleapis.com/css?family=Squada+One);
body {
	background-color: #fff;
	font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", sans-serif;
	margin: 0;
}

a {
	color: inherit; /* blue colors for links too */
	text-decoration: inherit; /* no underline */
}

#config-overlay {
	display: flex;
	flex-flow: column;
	z-index: 5;
}

@-webkit-keyframes stripe-slide {
	0% {
		background-position: 0% 0;
	}
	100% {
		background-position: 100% 0;
	}
}

@keyframes stripe-slide {
	0% {
		background-position: 0% 0;
	}
	100% {
		background-position: 100% 0;
	}
}

#config-overlay > ul {
	list-style-type: none;
	margin: 0 0 15px 0;
	padding: 0;
	text-align: center;
}

/* Edited from source: https://codepen.io/Zeindelf/pen/vZbyEg */

.config-btn {
	overflow: visible;
	margin: 0 20px;
	padding: 0;
	border: 0;
	background: transparent;
	font: inherit;
	line-height: normal;
	cursor: pointer;
	-moz-user-select: text;
	text-decoration: none;
	text-transform: uppercase;
	padding: 16px 36px 22px;
	background-color: rgba(0, 0, 0, 0.8);
	color: #fff;
	border: 2px solid #444;
	border-radius: 4px;
	transition: all 0.5s ease;
}
.config-btn::-moz-focus-inner {
	padding: 0;
	border: 0;
}
.config-btn--stripe {
	overflow: hidden;
	position: relative;
}
.config-btn--stripe:after {
	content: "";
	display: block;
	height: 7px;
	width: 100%;
	background-image: repeating-linear-gradient(45deg, #666, #666 1px, transparent 2px, transparent 5px);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-top: 1px solid #666;
	position: absolute;
	left: 0;
	bottom: 0;
	background-size: 7px 7px;
}

.config-btn--stripe:hover,
.active {
	background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border-color: #000;
}

.config-btn--stripe:hover:after,
.active:after {
	background-image: repeating-linear-gradient(45deg, #fff, #fff 1px, transparent 2px, transparent 5px);
	border-top: 1px solid #000;
	-webkit-animation: stripe-slide 12s infinite linear forwards;
	        animation: stripe-slide 12s infinite linear forwards;
}

.config-container {
	margin: 15px 30px;
	border-radius: 4px;
	background: white;
	width: 375px;
}

.slider-container {
	padding: 15px;
	padding-bottom: 10px;
	display: block;
	margin: 30px auto;
}

.font-container {
	padding: 15px;
	line-height: 1.6;
	display: block;
	margin: 30px auto;
}

.rangeslider .rangeslider__handle {
	border: unset;
}

@media screen and (max-width: 833px) {
	.config-container {
		width: 70%;
		margin: 15px auto;
	}
	.config-btn {
		margin: 0 10px;
		padding: 10px 20px 15px;
    	font-size: 12px;
	}

	.slider-container {
		padding: 15px;
		padding-bottom: 10px;
		display: block;
		margin: 30px auto;
		zoom: 0.6;
		width: 30%;
	}
	
	.font-container {
		padding: 15px;
		line-height: 1.6;
		display: block;
		margin: 30px auto;
		zoom: 0.6;
		width: 30%;
	}
}

.container {
  overflow-x: hidden;
  margin: auto;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.zoom-frame {
  z-index: 1;
}

@media only screen and (max-width: 1279px) {
  .container {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    overflow-x: hidden;
  }
}

.header {
  list-style-type: none;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: black;
  align-self: flex-start;
}

.header li {
  display: inline-block;
}

.header a, .header span, .header button {
  font-size: 36px;
  display: block;
  color: white;
  text-align: center;
  padding: 8px 22px;
  text-decoration: none;
}

.exit-button {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.exit-button button {
  transition: 300ms;
  cursor: pointer;
  background-color: unset;
  border: unset;
}

.exit-button button:hover {
  background: #e61919;
}

.exit-button button:focus {
  outline: unset;
}

.bg {
  position: fixed;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1279px) {
  .bg {
    /* width: unset; */
  }
}

.overlay {
  position: absolute;
  
  height: 100%;
  z-index: 2;
  /* background-color: #4ea6ca; */
}

#spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.viewPortrait {
  display: none;
}

.viewPortraitImg {
  height: 100%;
  margin: 0 auto;
  display: block;
}

@media only screen and (max-width: 500px) {
  .viewPortrait {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #1b1b1b;
    z-index: 4;
  }
}
.backlog-overlay {
  overflow: auto;
  margin: auto;
  z-index: 5;
}

.backlog {
  box-sizing: border-box;
  background-color: #eee;
  background-image: linear-gradient(90deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px),
    linear-gradient(#ddd 2px, transparent 2px);
  background-size: 100% 33.33%;
  font-size: 22px;
  width: 90%;
  margin: auto;
  height: 104px;
  padding-top: 3px;
  line-height: 1.52;
  padding-left: 93px;
}

.backlog-jump-container {
  position: absolute;
  transform: translate(-80px, 33px);
  color: #2d5986;
  font-style: italic;
  cursor: pointer;
}

.backlog-jump-text {
  transition: all 0.2s ease-in-out;
  position: relative;
}

.backlog-jump-text:after {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 0px;
  height: 2px;
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
  left: 0px;
  background-color: #2d5986;
}
.backlog-jump-container:hover .backlog-jump-text:before,
.backlog-jump-container:hover .backlog-jump-text:after {
  width: 100%;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .backlog {
    background-image: linear-gradient(90deg, transparent 50px, #abced4 50px, #abced4 52px, transparent 52px),
      linear-gradient(#ddd 2px, transparent 2px); /* shifts blue line margin to left */
    padding-left: 59px;
    font-size: 18px;
    padding-top: 2px;
    line-height: 1.45;
    background-size: 100% 25%;
  }
  .backlog-jump-container {
    transform: translate(-55px, 26px);
  }
  .backlog-jump-text:after {
    bottom: -2.5px;
  }
}

.backlog-speaker {
  font-weight: 500;
  color: DarkGreen;
}

.overlay-choices {
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 2;
  display: flex;
  height: auto;
  justify-content: flex-end;
  flex-direction: column;
  transition: 1s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.overlay .choice-button {
  font-size: 25px;
  min-width: 200px;
  margin: 5px auto;
  background-color: unset;
  border: none;
  color: white;
  padding: 6px 0px;
  cursor: pointer;
  display: block;
}

.choices-container {
  background: linear-gradient(0deg, rgba(14, 45, 22, 0.5) 0%, rgba(0,0,0,0.5) 50%);
  background-clip: padding-box;
  margin: 30px;
  padding: 15px 50px;
  border: 5px solid rgba(255, 255, 255, 0.5);
  border-radius: 40px;
}

.gooseChoices .overlay-choices {
  transition: 0s;
  height: auto;
  bottom: unset;
  left: unset;
  right: 36%;
  top: 46%;
  justify-content: flex-start;
  opacity: 0.5;
  /* -webkit-transition: 20s; */
  /* -moz-transition: 20s; */
  /* right: 300px; */
  /* transform: rotate(45deg); */
}

.gooseChoices .choices-container {
  border: unset;
  /* margin: unset; */
  padding: 15px;
  background: unset;
}

.gooseChoices .choice-button {
  margin: unset;
  padding: unset;
  min-width: unset;
  font-size: 12px;
}

.gooseDisabled:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: not-allowed;
}

.gooseDisabled .choice-button {
  color: #555;
}

@media screen and (max-width: 833px) {
  .choices-container {
    padding: 15px;
    margin: 20px;
    border: 3px solid rgba(255, 255, 255, 0.5);
  }
  .overlay .choice-button {
    font-size: 18px;
    margin: 3px auto;
    padding: 3px 0px;
  }
}

/* .overlay-choices button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.overlay-choices button:active {
  background-color: rgba(255, 255, 255, 0.15);
} */

.overlay-choices button:focus {
  outline: unset;
}

.ripple {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.ripple:after {
    content: "";
    background: rgba(255,255,255,0.3);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    padding-top: 240%;
    padding-left: 240%;
    margin-top: -120%;
    margin-left: -120%;
    opacity: 0;
    transition: all 1s;
}

.ripple:active:after {
    padding-top: 0;
    padding-left: 0;
    margin-top: 0;
    margin-left: 0;
    opacity: 1;
    transition: 0s;
}
/* Add your CSS classes here */

/* pre-built effects */
/* slight edit from source: https://css-tricks.com/snippets/css/shake-css-keyframe-animation/ */
.shake {
  -webkit-animation: shake 0.7s;
          animation: shake 0.7s;
}

.bounce {
  -webkit-animation: bounce 0.4s;
          animation: bounce 0.4s;
}

.shrink {
  -webkit-animation: shrink 2s;
          animation: shrink 2s;
  transform-origin: bottom;
  transform: scale(0.5);
}

.shrunk {
  transform-origin: bottom;
  transform: scale(0.5);
}

.grow-back {
  -webkit-animation: grow-back 2s;
          animation: grow-back 2s;
  transform-origin: bottom;
  transform: scale(1);
}

.grow {
  -webkit-animation: grow 2s;
          animation: grow 2s;
  transform-origin: bottom;
  transform: scale(2);
}
.grown {
  transform-origin: bottom;
  transform: scale(2);
}

.shrink-back {
  -webkit-animation: shrink-back 2s;
          animation: shrink-back 2s;
  transform-origin: bottom;
  transform: scale(1);
}

@-webkit-keyframes shake {
  20%,
  80% {
    transform: translateX(4px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-8px);
  }

  40%,
  60% {
    transform: translateX(8px);
  }
}

@keyframes shake {
  20%,
  80% {
    transform: translateX(4px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-8px);
  }

  40%,
  60% {
    transform: translateX(8px);
  }
}

@-webkit-keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
}

@-webkit-keyframes shrink {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.5);
  }
}

@keyframes shrink {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.5);
  }
}

@-webkit-keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(2);
  }
}

@keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(2);
  }
}

@-webkit-keyframes grow-back {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes grow-back {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@-webkit-keyframes shrink-back {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
}

@keyframes shrink-back {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
}


.rangeslider-horizontal .rangeslider__handle:after {
  top: 7px;
  left: 7.2px;
}
.loading-block {
	z-index: 5;
    position: absolute;
    height: 80px;
    width: 80px;
    bottom: 0;
    padding: 20px;
}

.loading-block svg {
    -webkit-filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.8));
            filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.8));
}

@media screen and (max-width: 833px) {
    .loading-block {
        height: 40px;
        width: 40px;
        padding-top: 0;
    }
}
.game-menu {
  width: 100%;
  position: absolute;
  top: 0;
}

.menu-buttons-container {
  z-index: 3;
  overflow: visible;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px;
}

@media screen and (max-width: 833px) {
  .menu-buttons-container {
    right: 10px;
  }
}

.settings-button {
  max-width: 70px;
  align-self: center;
  background: transparent;
  -webkit-filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.5));
  filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.5));
  transition: all 0.4s ease;
  color: #41403e;
  font-size: 24px;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.8;
}

@media screen and (max-width: 833px) {  
  .settings-button {
    max-width: 40px;
  }
}


.settings-button:hover {
  /* For hidden button to appear from transparent */
  /* -webkit-filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.8));
  filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.8)); */
  transform: rotate(23deg);
  opacity: 1;
}


/* Not using anymore */

.menu-buttons {}

.menu-buttons button {
  /* credit source https://codepen.io/tmrDevelops/full/VeRvKX/ */
  border: solid 2px #41403e; /* can substitute for others */
  align-self: center;
  background: transparent;
  padding: 14px;
  transition: all 0.5s ease;
  color: #41403e;
  font-size: 24px;
  letter-spacing: 1px;
  outline: none;
  box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.2);
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
  /*
  Above is shorthand for:
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  border-bottom-right-radius: 225px 15px;
  border-bottom-left-radius:15px 255px;
  */
  margin-right: 15px;
  cursor: pointer;
}

.menu-buttons button:hover {
  background-color: #1b9878;
  /* For hidden button to appear from transparent */
  box-shadow: 2px 8px 4px -6px rgba(0, 0, 0, 0.3);
  color: #fdfdfd; /* same as bg color */
  border: solid 2px #41403e;
}

@media only screen and (max-width: 1100px) {
  .menu-buttons button {
    font-size: 18px;
    padding: 8px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .menu-buttons button {
    float: none;
    font-size: 16px;
    padding: 6px;
    margin-top: 10px;
  }
}

.menu-buttons button:last-child {
  margin-right: 0; /* for symmetry; prevents rightmost button margin*/
}


.overlay-save-load {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #4ea6ca;
  z-index: 5;
  align-items: center;
}
@media only screen and (max-width: 1279px) {
  .overlay-save-load {
    overflow: auto;
  }
}

.save-load-slot {
  overflow: hidden;
  min-width: 1280px;
  min-height: 710px;
  transform: translateY(-80px) scale(0.625);
  background: #000;
  cursor: pointer;
  box-shadow: 0px 0 20px 2px #222;
  align-self: flex-start;
  transition: 0.2s;
}

/* @media only screen and (max-width: 880px) {
  .save-load-slot {
    transform: translateY(-80px) scale(0.45);
  }
}

@media only screen and (max-width: 600px) {
  .save-load-slot {
    transform: translateY(-80px) scale(0.275);
  }
} */

.save-load-slot:hover {
  box-shadow: 0px 0 40px 2px #222;
}

.save-load-slot button:hover {
  cursor: pointer;
}

.overlay-choices-slot {
  z-index: 9;
  width: 1280px;
  height: 720px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.save-load-slot > button > img {
  width: 100%;
  height: 100%;
}

.save-load-slot > .overlay-choices-slot {
  display: none;
}

.save-load-slot > button {
  display: flex;
  justify-content: center;
  transform: translate(0);
  background-color: unset;
  border: unset;
  padding: 0;
  height: 100%;
}

.slot-date {
  position: absolute;
  bottom: 110px;
  text-align: center;
  width: 100%;
  font-size: 26px;
  color: white;
}

.save-load-buttons {
  position: absolute;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  bottom: 50px;
  width: 100%;
  height: 40px;
}

.save-load-btn {
  padding: 10px 16px;
  margin: 2px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #0096cc;
  border: none;
  border-radius: 8px;
  /* box-shadow: 0 4px #007099; */
}

.save-load-btn:hover {
  background-color: #007099;
  transform: translateY(-4px);
}



@media screen and (max-width: 833px) {
  .save-load-slot {
    min-width: unset;
    min-height: unset;
    width: 60%;
    position: relative;
    top: 15px;
  }

  .empty {
    height: 60%;
  }

  .slot-date {
    font-size: 16px;
  }

  .save-load-btn {
    font-size: 10px;
    padding: 10px;
    margin: 5px;
  }

  .save-load-buttons{
    height: unset;
    bottom: 10px;
  }
}
.sprite {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	font-size: 0;
}

.sprite-center-parent {
	width: 1280px;
	height: 720px;
	position: fixed;
	left: 50%;
	margin-left: -640px;
	font-size: 0;
}

.left {
	left: -350px;
	right: 350px;
	font-size: 0;
}
.right {
	left: 350px;
	right: -350px;
	font-size: 0;
}

/* source https://codepen.io/Dreamdealer/pen/yEmwd */

#title-overlay {
  background: #eee
    url(https://subtlepatterns.com/patterns/extra_clean_paper.png);
}

#title-screen-header {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -75%);
  width: 100%;
}
#logo {
  width: 100%;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  color: #555;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 100px;
  font-weight: 800;
  letter-spacing: -3px;
  line-height: 1.3;
  text-shadow: #ededed 3px 2px 0;
}

#menu {
  width: 100%;
  height: 42px;
  list-style: none;
  margin: 100px 0 0 0;
  padding: 32px 0;
  border-top: 4px double #aaa;
  border-bottom: 4px double #aaa;
  position: relative;
  text-align: center;
}
#menu li {
  cursor: pointer;
  display: inline-block;
  width: 303px;
  margin: -8px 10px 0px;
  position: relative;
  -webkit-transform: skewy(-3deg);
  -webkit-backface-visibility: hidden;
  -webkit-transition: 200ms all;
}
#menu li span {
  text-transform: uppercase;
  font-family: "Squada One", cursive;
  font-weight: 800;
  display: block;
  background: #fff;
  padding: 6px 0;
  color: #333;
  font-size: 45px;
  text-align: center;
  text-decoration: none;
  position: relative;
  z-index: 1;
  text-shadow: 1px 1px 0px #fff, 2px 2px 0px #999, 3px 3px 0px #fff;
  background-image: -webkit-linear-gradient(
    top,
    transparent 0%,
    rgba(0, 0, 0, 0.05) 100%
  );
  -webkit-transition: 1s all;
  background-image: -webkit-linear-gradient(
    left top,
    transparent 0%,
    transparent 25%,
    rgba(0, 0, 0, 0.15) 25%,
    rgba(0, 0, 0, 0.15) 50%,
    transparent 50%,
    transparent 75%,
    rgba(0, 0, 0, 0.15) 75%
  );
  background-size: 4px 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4) inset,
    0 0 20px -5px rgba(0, 0, 0, 0.4), 0 0 0px 3px #fff inset;
}
#menu li:hover {
  width: 320px;
}
#menu span:hover {
  color: #d90075;
}
#menu li:after,
#menu li:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 100%;
  background: #bbb;
  -webkit-transform: skewY(8deg);
  x-index: -3;
  border-radius: 4px;
}
#menu li:after {
  background-image: -webkit-linear-gradient(
    left,
    transparent 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  right: 0;
  top: -4px;
}
#menu li:before {
  left: 0;
  bottom: -4px;
  background-image: -webkit-linear-gradient(
    right,
    transparent 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

@media only screen and (max-width: 1000px) {
  #logo {
    font-size: 80px;
  }

  #menu {
    padding: 28px 0 17px;
  }

  #menu li {
    width: 30%;
  }
  #menu li:hover {
    width: 32%;
  }

  #menu li span {
    font-size: 30px;
    margin-bottom: 15px;
  }

  #menu li:after,
  #menu li:before {
    content: none;
  }
}

@media only screen and (max-width: 700px) {
  #logo {
    font-size: 60px;
  }

  #menu {
    padding: 35px 0 125px;
  }

  #menu li {
    width: 70%;
  }
  #menu li:hover {
    width: 75%;
  }

  #menu li span {
    display: block;
    font-size: 30px;
    margin-bottom: 15px;
  }
}

/* React CSS Transition Groups animations*/

/* scene  */

.scene-change-enter {
  opacity: 0;
}

.scene-change-enter.scene-change-enter-active {
  opacity: 1;
  transition: opacity 2000ms ease-out;
}

.scene-change-leave {
  opacity: 1;
}

.scene-change-leave.scene-change-leave-active {
  opacity: 0;
  transition: opacity 2000ms ease-in;
}

/* gooose  */

.gooose-enter {
  opacity: 0;
}

.gooose-enter.gooose-enter-active {
  opacity: 0.5;
  transition: opacity 200000ms ease-out;
}

.gooose-leave {
  opacity: 1;
}

.gooose-leave.gooose-leave-active {
  opacity: 0;
  transition: opacity 400ms ease-in;
}

/* default  */

.default-transition-enter {
  opacity: 0;
}

.default-transition-enter.default-transition-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-out;
}

.default-transition-leave {
  opacity: 1;
}

.default-transition-leave.default-transition-leave-active {
  opacity: 0;
  transition: opacity 400ms ease-in;
}

/* 400  */

.transition-400-enter {
  opacity: 0;
}

.transition-400-enter.transition-400-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-out;
}

.transition-400-leave {
  opacity: 1;
}

.transition-400-leave.transition-400-leave-active {
  opacity: 0;
  transition: opacity 400ms ease-in;
}

/* 800  */

.transition-800-enter {
  opacity: 0;
}

.transition-800-enter.transition-800-enter-active {
  opacity: 1;
  transition: opacity 800ms ease-out;
}

.transition-800-leave {
  opacity: 1;
}

.transition-800-leave.transition-800-leave-active {
  opacity: 0;
  transition: opacity 800ms ease-in;
}

