.loading-block {
	z-index: 5;
    position: absolute;
    height: 80px;
    width: 80px;
    bottom: 0;
    padding: 20px;
}

.loading-block svg {
    filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.8));
}

@media screen and (max-width: 833px) {
    .loading-block {
        height: 40px;
        width: 40px;
        padding-top: 0;
    }
}