.game-menu {
  width: 100%;
  position: absolute;
  top: 0;
}

.menu-buttons-container {
  z-index: 3;
  overflow: visible;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px;
}

@media screen and (max-width: 833px) {
  .menu-buttons-container {
    right: 10px;
  }
}

.settings-button {
  max-width: 70px;
  align-self: center;
  background: transparent;
  -webkit-filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.5));
  filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.5));
  transition: all 0.4s ease;
  color: #41403e;
  font-size: 24px;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.8;
}

@media screen and (max-width: 833px) {  
  .settings-button {
    max-width: 40px;
  }
}


.settings-button:hover {
  /* For hidden button to appear from transparent */
  /* -webkit-filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.8));
  filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.8)); */
  transform: rotate(23deg);
  opacity: 1;
}


/* Not using anymore */

.menu-buttons {}

.menu-buttons button {
  /* credit source https://codepen.io/tmrDevelops/full/VeRvKX/ */
  border: solid 2px #41403e; /* can substitute for others */
  -ms-flex-item-align: center;
  align-self: center;
  background: transparent;
  padding: 14px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: #41403e;
  font-size: 24px;
  letter-spacing: 1px;
  outline: none;
  -webkit-box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.2);
  box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.2);
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
  /*
  Above is shorthand for:
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  border-bottom-right-radius: 225px 15px;
  border-bottom-left-radius:15px 255px;
  */
  margin-right: 15px;
  cursor: pointer;
}

.menu-buttons button:hover {
  background-color: #1b9878;
  /* For hidden button to appear from transparent */
  -webkit-box-shadow: 2px 8px 4px -6px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 8px 4px -6px rgba(0, 0, 0, 0.3);
  color: #fdfdfd; /* same as bg color */
  border: solid 2px #41403e;
}

@media only screen and (max-width: 1100px) {
  .menu-buttons button {
    font-size: 18px;
    padding: 8px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .menu-buttons button {
    float: none;
    font-size: 16px;
    padding: 6px;
    margin-top: 10px;
  }
}

.menu-buttons button:last-child {
  margin-right: 0; /* for symmetry; prevents rightmost button margin*/
}

