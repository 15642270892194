/* source https://codepen.io/Dreamdealer/pen/yEmwd */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,800,700,300);
@import url(https://fonts.googleapis.com/css?family=Squada+One);

#title-overlay {
  background: #eee
    url(https://subtlepatterns.com/patterns/extra_clean_paper.png);
}

#title-screen-header {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -75%);
  width: 100%;
}
#logo {
  width: 100%;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  color: #555;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 100px;
  font-weight: 800;
  letter-spacing: -3px;
  line-height: 1.3;
  text-shadow: #ededed 3px 2px 0;
}

#menu {
  width: 100%;
  height: 42px;
  list-style: none;
  margin: 100px 0 0 0;
  padding: 32px 0;
  border-top: 4px double #aaa;
  border-bottom: 4px double #aaa;
  position: relative;
  text-align: center;
}
#menu li {
  cursor: pointer;
  display: inline-block;
  width: 303px;
  margin: -8px 10px 0px;
  position: relative;
  -webkit-transform: skewy(-3deg);
  -webkit-backface-visibility: hidden;
  -webkit-transition: 200ms all;
}
#menu li span {
  text-transform: uppercase;
  font-family: "Squada One", cursive;
  font-weight: 800;
  display: block;
  background: #fff;
  padding: 6px 0;
  color: #333;
  font-size: 45px;
  text-align: center;
  text-decoration: none;
  position: relative;
  z-index: 1;
  text-shadow: 1px 1px 0px #fff, 2px 2px 0px #999, 3px 3px 0px #fff;
  background-image: -webkit-linear-gradient(
    top,
    transparent 0%,
    rgba(0, 0, 0, 0.05) 100%
  );
  -webkit-transition: 1s all;
  background-image: -webkit-linear-gradient(
    left top,
    transparent 0%,
    transparent 25%,
    rgba(0, 0, 0, 0.15) 25%,
    rgba(0, 0, 0, 0.15) 50%,
    transparent 50%,
    transparent 75%,
    rgba(0, 0, 0, 0.15) 75%
  );
  background-size: 4px 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4) inset,
    0 0 20px -5px rgba(0, 0, 0, 0.4), 0 0 0px 3px #fff inset;
}
#menu li:hover {
  width: 320px;
}
#menu span:hover {
  color: #d90075;
}
#menu li:after,
#menu li:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 100%;
  background: #bbb;
  -webkit-transform: skewY(8deg);
  x-index: -3;
  border-radius: 4px;
}
#menu li:after {
  background-image: -webkit-linear-gradient(
    left,
    transparent 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  right: 0;
  top: -4px;
}
#menu li:before {
  left: 0;
  bottom: -4px;
  background-image: -webkit-linear-gradient(
    right,
    transparent 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

@media only screen and (max-width: 1000px) {
  #logo {
    font-size: 80px;
  }

  #menu {
    padding: 28px 0 17px;
  }

  #menu li {
    width: 30%;
  }
  #menu li:hover {
    width: 32%;
  }

  #menu li span {
    font-size: 30px;
    margin-bottom: 15px;
  }

  #menu li:after,
  #menu li:before {
    content: none;
  }
}

@media only screen and (max-width: 700px) {
  #logo {
    font-size: 60px;
  }

  #menu {
    padding: 35px 0 125px;
  }

  #menu li {
    width: 70%;
  }
  #menu li:hover {
    width: 75%;
  }

  #menu li span {
    display: block;
    font-size: 30px;
    margin-bottom: 15px;
  }
}
